<!-- 邀请注册第二步 输入企业名称 -->
<template>
    <div class="join1">
        <div class="flex">
            <div class="join1-img">
                <img src="../../assets/images/user/register-invite.png">
            </div>
            <div class="flex-1">
                <div class="join1-title">欢迎加入{{compName && compName.name}}</div>
                <div class="join1-text">请确认加入的企业是否正确，若正确，请点击下一步</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCompByCode } from '@/service/company';
    export default {
        props:['comp_code'],
        data() {
            return {
                compName:null,
            }
        },
        watch:{
            comp_code:{
                handler(newValue, oldValue) {
                    this.get_compName(newValue);
                },
                deep: true
            }
        },
        async created() {
            this.get_compName(this.comp_code);
        },
        methods: {
            async get_compName(newValue) {
                this.compName = await getCompByCode(newValue);
                console.log(this.compName);
            },
            submitForm2() {
                this.$store.state.regStep=3;
            },
        }
    }
</script>
<style scoped>
    .join1 {
        padding: 30px 75px;
    }
    .join1-img img {
        width: 158px;
        margin-right: 5px;
    }
    .join1-title{
        font-size:20px;
        font-weight:bold;
        color: #292929;
        padding: 25px 0;
    }
    .join1-text{
        font-size:14px;
        color: #B3B3B3;
        line-height: 20px;
    }
</style>