<!-- 邀请注册 -->
<template>
    <div>
        <div style="width:254px;margin:0 auto;">
            <el-form v-if="$store.state.regStep==1" :model="ruleForm" :rules="rules" ref="form_check">
                <el-form-item prop="invite_code">
                    <el-input v-model="ruleForm.invite_code" placeholder="请输入邀请码"></el-input>
                </el-form-item>
                <el-form-item style="margin-bottom:15px;" prop="comp_code">
                    <el-input v-model="ruleForm.comp_code" placeholder="请输入机构号"></el-input>
                </el-form-item>
                <!-- <div style="margin-bottom:15px;" class="flex">
                    <el-checkbox style="padding:5px 0;font-size:12px;color:#808080;line-height:19px;" v-model="clause">我已阅读并同意中国家居产业智联网条款</el-checkbox>
                </div> -->
                <!-- <el-form-item style="text-align:center;">
                    <el-button v-if="clause" @click="verify_phone('ruleForm')" style="width:75px;" size="small" type="primary">下一步</el-button>
                    <el-button v-else style="width:75px;" size="small" type="info" disabled>下一步</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <div>
            <Join1 v-if="$store.state.regStep==2" :comp_code="ruleForm.comp_code" ref="form_check2"></Join1>
            <Join2 v-if="$store.state.regStep==3" v-model="ruleForm" ref="form_check3"></Join2>
        </div>

        <div style="width:240px;margin:30px auto 0;" class="flex flex-justify-around">
            <el-button v-if="$store.state.regStep!=1" @click="back()" style="width:75px;" size="small" type="primary" plain>上一步</el-button>
            <el-button v-if="$store.state.regStep==1" @click="submitForm()" style="width:75px;" size="small" 
            :type="clause ? 'primary' : 'info'"
            :disabled="!clause">下一步</el-button>
            <el-button v-else @click="onStep()" style="width:75px;" size="small" type="primary">下一步</el-button>
        </div>
        <div style="text-align:center;padding-top:15px;">已有账号？<el-button @click="goto('/login')" type="text">请登录</el-button></div>

    </div>
</template>
<script>
    import { verifyInviteCode } from '@/service/account';
    import Join1 from "./Join1.vue";
    import Join2 from "./Join2.vue";
    export default {
        components: {
            Join1,Join2
        },
        props: {},
        data() {
            return {
                ruleForm: {
                    comp_code: '',
                    invite_code: '',
                    user_name: '',
                    user_mobile:'',
                    user_pass:'',
                    mobile_code:'',
                    captcha:'',
                    captcha_token:'',
                },
                rules: {
                    invite_code: [
                        { required: true, message: '请输入邀请码', trigger: 'blur' }
                    ],
                    comp_code: [
                        { required: true, message: '请输入机构号', trigger: 'blur' }
                    ]
                },
                clause:true,
            }
        },
        mounted() {
            // this.$store.state.regStep=2;
        },
        methods: {
            onStep() {
                switch(this.$store.state.regStep) {
                    case 1:
                        break;
                    case 2:
                        this.$refs.form_check2.submitForm2(); break;
                    case 3:
                        this.$refs.form_check3.verify_phone(); break;
                }
            },
            back() {
                if (this.$store.state.regStep>1) {
                    this.$store.state.regStep--;
                }
            },
            goto(path) {
                this.$router.push(path);
            },
            submitForm() {
                this.$refs['form_check'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                verifyInviteCode(this.ruleForm).then(() => {
                    this.$store.state.regStep=2;
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }

</script>
<style>
    .reg-create-captcha-ipt .el-input-group__append {
        padding: 0 !important;
        overflow: hidden;
    }
</style>
<style scoped>
    .form-title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(77, 77, 77, 1);
    }

    .captcha-img {
        height: 38px;
        background-size: 100% 100% !important;
        width: 110px;
        cursor: pointer;
    }
</style>